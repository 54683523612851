.App {
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  position: relative;
}
h1 {
  font-family: 'Montserrat', sans-serif;
}
h1, p {
  color: #fff;
}
.btn-light {
  color: black !important;

}
.image {
  width: 200px;
  height: 200px;
  border: 3px solid #fff;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  top: 20%;
  bottom: 0;
  margin: auto;
  z-index: 1;
}
